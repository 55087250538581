import React, { forwardRef } from 'react';
import classNames from 'classnames';

const SidebarFooter = ({ children, className, ...rest }, ref) => {
    const sidebarFooterRef = ref || React.createRef();
    return (
        <div ref={sidebarFooterRef} className={classNames('fm-sidebar-footer', className)} {...rest}>
            {children}
        </div>
    );
};

export default forwardRef(SidebarFooter);
