import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    font-size: 18px;
    font-weight: bolder;
    margin-top: 5px;
    margin-bottom: 15px;
`;

export default function Title({ children, style, ...props }) {
    return (
        <Container style={style} {...props}>
            {children}
        </Container>
    );
}
