import React from 'react';
import ErrorComponent from './components/Error';
import TitleComponent from './components/Title';
import DescComponent from './components/Desc';

export const colors = {
    textColor: '#000000',
    textColor2: '#444950',
    dark: '#1C1C1C',
    dark1: '#4E4E4E',
    dark2: '#626262',
    dark5: '#BCBCBC',
    lightDark: '#C4C4C4',
    grey: '#EFEFEF',
    white: '#FFFFFF',
    primaryColor: '#ff6720',
    defaultColor: '#ff6720',
    primaryDarkColor: '#ff6720',
    escrowPayitPrimaryColor: '#71bce9',
    escrowPayitDefaultColor: '#95bad1',
    // primaryDarkColor: '#95bad1',
    primaryColor2: '#FFEED5',
    primaryColor3: '#FFF7EB',
    secondaryColor: '#2A2A72',
    red: '#FF0000',
    write: '#fff',
    green: '#0B973A',
    fontFamily: 'Poppins',
    backgroundColor: '#f2f2f2',
};

export function Error({ error, children }) {
    return <ErrorComponent colors={colors}>{children}</ErrorComponent>;
}

export function Title({ children, style, ...props }) {
    return (
        <TitleComponent style={style} {...props}>
            {children}
        </TitleComponent>
    );
}

export function Desc({ children, ...props }) {
    return (
        <DescComponent colors={colors} {...props}>
            {children}
        </DescComponent>
    );
}
