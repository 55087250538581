import React, { Suspense, useState } from 'react';
import { Routes } from 'react-router-dom';
import styled from 'styled-components';
import { PagePreloader } from '@gavel/components/Preloader';

import Sidebar from './components/Sidebar';
import Header from './components/Header';

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
`;

const Content = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    overflow-x: hidden;
    background: #f2f2f2;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px;
`;

export default function Desktop({ children }) {
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    return (
        <Container>
            <Sidebar
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            />
            <Content>
                <ContentWrapper>
                    <Header />
                    <ContentContainer>
                        <Suspense fallback={<PagePreloader />}>
                            <Routes>{children}</Routes>
                        </Suspense>
                    </ContentContainer>
                </ContentWrapper>
            </Content>
        </Container>
    );
}

export const DashboardPageLists = ['dashboard'];
