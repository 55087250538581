import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    color: ${({ colors }) => colors.dark2};
`;

export default function Desc({ children, colors, ...props }) {
    return (
        <Container colors={colors} {...props}>
            {children}
        </Container>
    );
}
