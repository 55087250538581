import React from 'react';
import styled from 'styled-components';
import NativeStyled from 'rn-css';
import Logo from '@components/Assets/Logo/podus_logo.png';
import { BiMenuAltRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export { default as MobileMenuHeader } from './components/MobileMenuHeader';
export { default as MobileMenu } from './components/MobileMenu';

const Container = styled.div`
    background: #fff;
    border-bottom: 2px solid rgba(191, 191, 191, 0.15);
`;

const MainContainer = styled.div`
    padding: 10px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;
`;

const LogoContainer = styled.img`
    width: 102px;
    height: 33px;
    margin-right: 10px;
`;

const MenuButton = NativeStyled.TouchableOpacity`
    cursor: pointer;
`;

export default function Header({ menuHandler }) {
    return (
        <>
            <Container>
                <MainContainer>
                    <Wrapper>
                        <Link to="/">
                            <LogoContainer src={Logo} />
                        </Link>
                        <MenuButton onPress={() => menuHandler()}>
                            <BiMenuAltRight size={34} color="#000" />
                        </MenuButton>
                    </Wrapper>
                </MainContainer>
            </Container>
        </>
    );
}
