import React, { useState, Suspense } from 'react';
import styled from 'styled-components';
import Footer from './components/Footer';
import Header, { MobileMenuHeader, MobileMenu } from './components/Header';
import { Routes } from 'react-router-dom';
import { PagePreloader } from '@gavel/components/Preloader';
import './index.css';

const Container = styled.div`
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100%;
    position: relative;
    background: #f2f2f2;
`;

const Wrapper = styled.div`
    height: calc(100vh - 62px);
    overflow-x: hidden;
    overflow-y: auto;
`;

export default function Containers({ children, noOtherComponents }) {
    const [state, setState] = useState({
        openMenu: false,
    });

    const stateHandler = (key, value) => {
        state[key] = value;
        setState({ ...state });
    };

    const menuHandler = () => {
        stateHandler('openMenu', !state.openMenu);
    };

    return (
        <Suspense fallback={<PagePreloader />}>
            <Container id="mobile_container" className={`mobile_container_wrapper ${state.openMenu && 'navigation'}`}>
                <>
                    <Header menuHandler={() => menuHandler()} />
                    <Wrapper>
                        <div id="nav">
                            <MobileMenuHeader menuHandler={() => menuHandler()} />
                            <MobileMenu />
                        </div>
                        <div id="mobile_container_body" style={{ padding: 10 }}>
                            <Routes>{children}</Routes>
                        </div>
                        <Footer />
                    </Wrapper>
                </>
            </Container>
        </Suspense>
    );
}
