import React from 'react';
import styled from 'rn-css';
import Modal from '@modules/Modal';
import { usePageActivityIndicator } from './usePageActivityIndicator';
import { colors } from '@components/typo';
import { ActivityIndicator, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    shadow: {
        shadowColor: '#FF6600',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
    },
});

const Container = styled.View`
    background-color: #fff;
    border-radius: 10px;
    height: 100px;
    width: 100px;
    justify-content: center;
    align-items: center;
    z-index: 10000;
`;

export default function PageActivityIndicator() {
    const pageActivityIndicator = usePageActivityIndicator();
    return (
        <Modal visible={pageActivityIndicator.get()} onRequestClose={() => pageActivityIndicator.set(false)}>
            <Container style={styles.shadow}>
                <ActivityIndicator color={colors.defaultColor} size="large" />
            </Container>
        </Modal>
    );
}
