import React, { lazy, useEffect } from 'react';
import { Route, useNavigate } from 'react-router-dom';
import Container from '@components/layouts/Container';
import { useUserDetails } from '@components/hooks';
import Cookies from 'js-cookie';
import PodusBaseInit from '@modules/PodusBase';
import Preloader from '../../components/Preloader';

const Dashboard = lazy(() => import(`../../pages/Dashboard/Dashboard`));
const AssignedCase = lazy(() => import(`../../pages/Dashboard/AssignedCase`));
const OngoingCases = lazy(() => import(`../../pages/Dashboard/OngoingCases`));
const NearbyCases = lazy(() => import(`../../pages/Dashboard/NearbyCases`));
const ClosedCases = lazy(() => import(`../../pages/Dashboard/ClosedCases`));
const Profile = lazy(() => import(`../../pages/Dashboard/Profile`));
const Notifications = lazy(() => import(`../../pages/Dashboard/Notifications`));
const ViewCase = lazy(() => import(`../../pages/Dashboard/ViewCase`));
const CreateCase = lazy(() => import(`../../pages/Dashboard/CreateCase`));
const NotAssignedCases = lazy(() => import(`../../pages/Dashboard/NotAssignedCases`));
const ContactUs = lazy(() => import(`../../pages/Dashboard/ContactUs`));
const AddAdmin = lazy(() => import(`../../pages/Dashboard/AddAdmin`));
const Users = lazy(() => import(`../../pages/Dashboard/Users`));
const Lawyers = lazy(() => import(`../../pages/Dashboard/Lawyers`));
const Admins = lazy(() => import(`../../pages/Dashboard/Admins`));
const Companies = lazy(() => import(`../../pages/Dashboard/Companies`));
const TrackCase = lazy(() => import(`../../pages/Dashboard/TrackCase`));
const NewCases = lazy(() => import(`../../pages/Dashboard/NewCases`));
const UnassignedCase = lazy(() => import(`../../pages/Dashboard/UnassignedCase`));
const Rejected = lazy(() => import(`../../pages/Dashboard/Rejected`));
const AllCases = lazy(() => import(`../../pages/Dashboard/AllCases`));

export default function AccessPages() {
    const userDetails = useUserDetails();
    const users = userDetails.get();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const PodusBase = await PodusBaseInit;
            const getUserToken = Cookies.get('Podus_uft_token');

            if (getUserToken != 'undefined' && typeof getUserToken != 'undefined') {
                const getter = await PodusBase.post('user');
                getter.on('static', (data) => {
                    if (data.Details) {
                        userDetails.set(data.Details);
                    } else {
                        navigate('/login', { replace: false });
                    }
                });
            } else {
                navigate('/login', { replace: false });
            }
        })();
    }, []);

    return (
        <Container>
            <Route path="/">
                {users ? (
                    <>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="assigned-cases" element={<AssignedCase />} />
                        <Route path="ongoing-cases" element={<OngoingCases />} />
                        <Route path="nearby-cases" element={<NearbyCases />} />
                        <Route path="closed-cases" element={<ClosedCases />} />
                        <Route path="profile/:username" element={<Profile />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="case/:caseID" element={<ViewCase />} />
                        <Route path="create-case" element={<CreateCase />} />
                        <Route path="notassigned-cases" element={<NotAssignedCases />} />
                        <Route path="contact-us" element={<ContactUs />} />
                        <Route path="add-admin" element={<AddAdmin />} />
                        <Route path="users" element={<Users />} />
                        <Route path="lawyers" element={<Lawyers />} />
                        <Route path="admins" element={<Admins />} />
                        <Route path="companies" element={<Companies />} />
                        <Route path="track-case" element={<TrackCase />} />
                        <Route path="new-cases" element={<NewCases />} />
                        <Route path="unassigned-cases" element={<UnassignedCase />} />
                        <Route path="all-cases" element={<AllCases />} />
                        <Route path="rejected-cases" element={<Rejected />} />
                    </>
                ) : null}
            </Route>
        </Container>
    );
}

export const DashboardPageLists = [
    'dashboard',
    'assigned-cases',
    'ongoing-cases',
    'nearby-cases',
    'closed-cases',
    'profile',
    'case',
    'notifications',
    'create-case',
    'notassigned-cases',
    'contact-us',
    'add-admin',
    'users',
    'lawyers',
    'admins',
    'companies',
    'track-case',
    'new-cases',
    'unassigned-cases',
    'all-cases',
    'rejected-cases',
];
