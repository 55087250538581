import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { PagePreloader } from '@components/Preloader';
import styled from 'styled-components';

const MainContainer = styled.div``;

const LandingPage = lazy(() => import(`../../pages/Acquisition/pages/LandingPage`));
const Login = lazy(() => import(`../../pages/Acquisition/pages/Login`));
const ForgotPassword = lazy(() => import(`../../pages/Acquisition/pages/ForgotPassword`));
const ResetPassword = lazy(() => import(`../../pages/Acquisition/pages/ResetPassword`));
const VerifiedPage = lazy(() => import(`../../pages/Acquisition/pages/VerifiedPage`));
const InstantCaseVerifyPage = lazy(() => import(`../../pages/Acquisition/pages/InstantCaseVerifyPage`));

export default function Acquisition() {
    return (
        <MainContainer>
            <Suspense fallback={<PagePreloader />}>
                <Routes>
                    <Route path="/">
                        <Route index element={<LandingPage />} />
                        <Route exact path="/" element={<LandingPage />} />
                        <Route exact path="/trackcase" element={<LandingPage />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/sign-up" element={<Login />} />
                        <Route exact path="/forgot-password" element={<ForgotPassword />} />
                        <Route exact path="/reset-password" element={<ResetPassword />} />
                        <Route exact path="/verified" element={<VerifiedPage />} />
                        <Route exact path="/instant-case-verification" element={<InstantCaseVerifyPage />} />
                    </Route>
                </Routes>
            </Suspense>
        </MainContainer>
    );
}

export const AcquisitionPageLists = [
    '',
    'login',
    'sign-up',
    'forgot-password',
    'verified',
    'reset-password',
    'instant-case-verification',
    'trackcase',
];
