import React, { useState, useEffect } from 'react';
import { useUserDetails } from '@components/hooks';
import { colors } from '@gavel/components/Typo';
import { AiOutlineDashboard, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FaUsers, FaUserTie, FaFolderOpen, FaUserCircle, FaRegBell } from 'react-icons/fa';
import { MenuItem } from '../../Desktop/components/Sidebar/Structure';
import { BsFolderSymlinkFill } from 'react-icons/bs';
import {
    MdSupportAgent,
    MdOutlineSnippetFolder,
    MdOutlineFolderSpecial,
    MdFolder,
    MdFolderSpecial,
} from 'react-icons/md';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';

export default function SuperAdmin() {
    const userDetails = useUserDetails();
    const users = userDetails.get();

    const [page, setPage] = useState('/');
    const location = useLocation();

    useEffect(() => {
        setPage(location.pathname);
    }, [location]);

    return (
        <>
            <MenuItem
                active={page == '/dashboard'}
                to="/dashboard"
                icon={<AiOutlineDashboard color={colors.primaryColor} size={24} />}
            >
                Dashboard
            </MenuItem>
            <MenuItem
                active={page == '/add-admin'}
                to="/add-admin"
                icon={<AiOutlineUsergroupAdd color={colors.primaryColor} size={24} />}
            >
                Add Admin
            </MenuItem>
            <MenuItem active={page == '/users'} to="/users" icon={<FaUsers color={colors.primaryColor} size={24} />}>
                Users
            </MenuItem>
            <MenuItem
                active={page == '/lawyers'}
                to="/lawyers"
                icon={<FaUserTie color={colors.primaryColor} size={24} />}
            >
                Lawyers
            </MenuItem>
            <MenuItem
                active={page == '/admins'}
                to="/admins"
                icon={<MdSupportAgent color={colors.primaryColor} size={24} />}
            >
                Admins
            </MenuItem>
            <MenuItem
                active={page == '/companies'}
                to="/companies"
                icon={<HiOutlineOfficeBuilding color={colors.primaryColor} size={24} />}
            >
                Companies
            </MenuItem>
            <MenuItem
                active={page == '/track-case'}
                to="/track-case"
                icon={<MdOutlineSnippetFolder color={colors.primaryColor} size={24} />}
            >
                Track Case
            </MenuItem>
            <MenuItem
                active={page == '/new-cases'}
                to="/new-cases"
                icon={<MdOutlineFolderSpecial color={colors.primaryColor} size={24} />}
            >
                New Cases
            </MenuItem>
            <MenuItem
                active={page == '/ongoing-cases'}
                to="/ongoing-cases"
                icon={<FaFolderOpen color={colors.primaryColor} size={24} />}
            >
                Ongoing Cases
            </MenuItem>
            <MenuItem
                active={page == '/closed-cases'}
                to="/closed-cases"
                icon={<MdFolder color={colors.primaryColor} size={24} />}
            >
                Closed Cases
            </MenuItem>
            <MenuItem
                active={page == '/unassigned-cases'}
                to="/unassigned-cases"
                icon={<MdFolderSpecial color={colors.primaryColor} size={24} />}
            >
                Unassigned Cases
            </MenuItem>
            <MenuItem
                active={page == '/rejected-cases'}
                to="/rejected-cases"
                icon={<MdFolderSpecial color={colors.primaryColor} size={24} />}
            >
                Rejected Cases
            </MenuItem>
            <MenuItem
                active={page == `/profile/${users.profileurl}`}
                to={`/profile/${users.profileurl}`}
                icon={<FaUserCircle color={colors.primaryColor} size={24} />}
            >
                Profile
            </MenuItem>
            <MenuItem
                active={page == '/notifications'}
                to="/notifications"
                icon={<FaRegBell color={colors.primaryColor} size={24} />}
            >
                Notifications
            </MenuItem>
        </>
    );
}
