import { createState, useState } from '@hookstate/core';

const globalState = createState(false);
const wrapState = (s) => ({
    get: () => s.value,
    set: (value) => s.set(value),
});

export default function useUserDetails() {
    return wrapState(useState(globalState));
}
