import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Notifier from '@gavel/modules/Notifier';
import Route from './routes';
import '@gavel/components/Styles/app.css';
import './components/styles/App.css';
import PageActivityIndicator from '@components/libs/PageActivityIndicator';

export default function App() {
    return (
        <BrowserRouter>
            <Route />
            <Notifier />
            <PageActivityIndicator />
        </BrowserRouter>
    );
}
