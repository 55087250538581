import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { IoShieldCheckmarkSharp } from 'react-icons/io5';
import { BsInfoCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { colors } from '@gavel/components/Typo';
import Modal from '@gavel/modules/Modal';
import { useNotifierState } from './useNotifierState';

const Container = styled.div`
    background-color: #fff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 3000000;
    width: 300px;
    display: flex;
    flex-direction: column;
`;

const CloseButton = styled.button`
    padding: 4px;
    border-radius: 5px;
    background-color: ${colors.grey};
`;

const AlertTitle = styled.div`
    font-weight: 600;
`;

const AlertDesc = styled.div`
    text-align: center;
    margin: 5px;
`;

export default function Alert() {
    const alertState = useNotifierState();
    const alertObject = {
        state: false,
        title: '',
        desc: '',
        type: 'error',
        closeText: false,
        onClose: () => null,
    };
    const alert = alertState.get();
    return (
        <Modal visible={alert.state} onRequestClose={() => alertState.set(alertObject)}>
            <Container>
                {alert.type === 'error' && <MdErrorOutline size={30} color={colors.red} />}
                {alert.type === 'success' && <IoShieldCheckmarkSharp size={30} color={colors.green} />}
                {(typeof alert.type == 'undefined' || alert.type == 'info') && (
                    <BsInfoCircleFill size={30} color={colors.defaultColor} />
                )}
                <AlertTitle>{alert.title}</AlertTitle>
                <AlertDesc>{alert.desc}</AlertDesc>
                <CloseButton
                    onClick={() => {
                        alert.onClose && alert.onClose();
                        alertState.set(alertObject);
                    }}
                >
                    <div>{alert.closeText ? alert.closeText : 'Close'}</div>
                </CloseButton>
            </Container>
        </Modal>
    );
}
