import React from 'react';
import styled from 'styled-components';
import Toggle from './Toggle';
import { Sidebar, Menu, SidebarHeader, SidebarContent } from './Structure';
import SidebarLogic from '../../../Sidebars';

import Logo from '@components/Assets/Logo/podus_logo.png';

const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LogoWrapper = styled.img`
    width: 100.85px;
    height: 32.91px;
`;

const SidebarLayout = ({ collapsed, rtl, toggled, handleToggleSidebar, handleCollapsedChange }) => {
    return (
        <Sidebar
            image={false}
            rtl={rtl}
            collapsed={collapsed}
            toggled={toggled}
            breakPoint="md"
            onToggle={handleToggleSidebar}
        >
            <SidebarHeader>
                {!collapsed && (
                    <LogoContainer>
                        <LogoWrapper src={Logo} />
                    </LogoContainer>
                )}
                <Toggle handleToggleSidebar={handleCollapsedChange} collapsed={collapsed} />
            </SidebarHeader>

            <SidebarContent>
                <Menu iconShape="circle">
                    <SidebarLogic />
                </Menu>
            </SidebarContent>
        </Sidebar>
    );
};

export default SidebarLayout;
