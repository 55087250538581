import React from 'react';
import styled from 'styled-components';
import { colors } from '@gavel/components/Typo';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Container = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 35px;
    background: #f2f2f2;
    z-index: 4;
    position: absolute;
    right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
`;

export default function Toggle({ handleToggleSidebar, collapsed }) {
    const onClick = () => handleToggleSidebar(!collapsed);
    return (
        <Container onClick={onClick}>
            {collapsed ? (
                <IoIosArrowForward color={colors.primaryColor} size={24} />
            ) : (
                <IoIosArrowBack color={colors.primaryColor} size={24} />
            )}
        </Container>
    );
}
