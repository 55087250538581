import React, { useEffect } from 'react';
import { Modal, StyleSheet, View, TouchableWithoutFeedback } from 'react-native';
import styled from 'rn-css';
import useHeaderState from '@gavel/landauth/src/versions/1.1/__components__/MainContainer/Header/useHeaderState';

const ModalPage = (props) => {
    const headerState = useHeaderState();
    const { children, onBackdropPress, contentStyle, visible, onRequestClose, ...otherProps } = props;

    useEffect(() => {
        if (visible) {
            headerState.set(true);
            document.body.style.overflowY = 'hidden';
        } else {
            headerState.set(false);
            document.body.style.overflowY = 'auto';
        }
    }, [visible]);

    return (
        <>
            <Modal transparent animationType="none" visible={visible} onRequestClose={() => onRequestClose()}>
                <TouchableWithoutFeedback onPress={onBackdropPress}>
                    <View
                        style={[
                            styles.backdrop,
                            { backgroundColor: !props.NoBackDrop ? 'rgba(0, 0, 0, 0.5)' : 'transparent' },
                        ]}
                    />
                </TouchableWithoutFeedback>
                <View style={[styles.content]}>
                    <ModalContainer style={props.style}>{children}</ModalContainer>
                </View>
            </Modal>
        </>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        opacity: 0.5,
        zIndex: 9999999999,
    },
    content: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999999999,
    },
});

const ModalContainer = styled.View`
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999999999999;
    @media (max-width: 576px) {
        width: 100%;
    }
    @media (min-width: 768px) {
        width: 576px;
    }
    @media (min-width: 992px) {
        width: 768px;
    }
    @media (min-width: 1200px) {
        width: 992px;
    }
    @media (min-width: 1400px) {
        width: 1200px;
    }
`;

export default ModalPage;
