import React, { useState, useEffect } from 'react';
import { useUserDetails } from '@components/hooks';
import { colors } from '@gavel/components/Typo';
import { AiOutlineDashboard, AiOutlineMessage } from 'react-icons/ai';
import { FaUserCircle, FaRegBell, FaFolderOpen } from 'react-icons/fa';
import { MenuItem } from '../../Desktop/components/Sidebar/Structure';
import { BsFolderSymlinkFill } from 'react-icons/bs';
import { MdFolder, MdCreateNewFolder, MdFolderSpecial } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

export default function Users() {
    const userDetails = useUserDetails();
    const users = userDetails.get();

    const [page, setPage] = useState('/');
    const location = useLocation();

    useEffect(() => {
        setPage(location.pathname);
    }, [location]);

    return (
        <>
            <MenuItem
                active={page == '/dashboard'}
                to="/dashboard"
                icon={<AiOutlineDashboard color={colors.primaryColor} size={24} />}
            >
                Dashboard
            </MenuItem>
            <MenuItem
                active={page == '/ongoing-cases'}
                to="/ongoing-cases"
                icon={<FaFolderOpen color={colors.primaryColor} size={24} />}
            >
                Ongoing Cases
            </MenuItem>
            <MenuItem
                active={page == '/assigned-cases'}
                to="/assigned-cases"
                icon={<BsFolderSymlinkFill color={colors.primaryColor} size={24} />}
            >
                Assigned Cases
            </MenuItem>
            <MenuItem
                active={page == '/create-case'}
                to="/create-case"
                icon={<MdCreateNewFolder color={colors.primaryColor} size={24} />}
            >
                Create Case
            </MenuItem>
            <MenuItem
                active={page == '/notassigned-cases'}
                to="/notassigned-cases"
                icon={<MdFolderSpecial color={colors.primaryColor} size={24} />}
            >
                Not Assigned Cases
            </MenuItem>
            <MenuItem
                active={page == '/closed-cases'}
                to="/closed-cases"
                icon={<MdFolder color={colors.primaryColor} size={24} />}
            >
                Closed Cases
            </MenuItem>
            <MenuItem
                active={page == `/profile/${users.profileurl}`}
                to={`/profile/${users.profileurl}`}
                icon={<FaUserCircle color={colors.primaryColor} size={24} />}
            >
                Profile
            </MenuItem>
            <MenuItem
                active={page == '/contact-us'}
                to="/contact-us"
                icon={<AiOutlineMessage color={colors.primaryColor} size={24} />}
            >
                Contact Us
            </MenuItem>
            <MenuItem
                active={page == '/notifications'}
                to="/notifications"
                icon={<FaRegBell color={colors.primaryColor} size={24} />}
            >
                Notifications
            </MenuItem>
        </>
    );
}
