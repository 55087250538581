import styled from 'rn-css';
import { StyleSheet } from 'react-native';

export const allstyles = StyleSheet.create({
    shadow: {
        shadowColor: 'rgb(34, 41, 47)',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
    },
});

export const colors = {
    backgroundColor: '#f0f7fc',
    textColor: '#5E5873',
    textColor2: '#707577',
    textColor3: '#000000',
    defaultColor: '#FF6600',
    fadedOrange: '#FFE8C6',
    grey: '#C4C4C4',
    white: '#fff',
};

export const Text = styled.Text`
    font-size: 15px;
    color: ${colors.textColor};
    line-height: 18px;
`;

export const TextBold = styled.Text`
    font-weight: bold;
    font-size: 15px;
    color: ${colors.textColor};
`;
