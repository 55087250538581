import React from 'react';
import styled from 'styled-components';
import Animate from './Animate';

export default function Preloader() {
    const Container = styled.div`
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `;

    return (
        <Container>
            <Animate height={50} width={50} />
        </Container>
    );
}

export function PagePreloader() {
    const Container = styled.div`
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `;

    return (
        <Container>
            <Animate height={50} width={50} />
        </Container>
    );
}
