import React from 'react';
import animationData from './animate.json';
import Lottie from 'react-lottie';

export default function Animate({ ...props }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: 'svg',
    };
    return <Lottie options={defaultOptions} {...props} />;
}
