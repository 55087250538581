import React, { useState } from 'react';
import styled from 'styled-components';
import NativeStyled from 'rn-css';
import { IoCloseSharp, IoChevronDown } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { colors } from '@gavel/components/Typo';
import { useUserDetails } from '@components/hooks';
import PodusBaseInit, { config } from '@modules/PodusBase';

const Container = styled.div`
    display: flex;
    height: 70px;
    background: #ffffff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: sticky;
    top: 0;
    border-bottom: 2px solid rgba(191, 191, 191, 0.15);
`;

const CloseButton = NativeStyled.TouchableOpacity`
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 11%);
`;

const NavbarButton = NativeStyled.TouchableOpacity`
    padding: 4px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: row;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    margin-left: 10px;
    a {
        display: flex;
        align-items: center;
    }
`;

const MenuContainer = styled.div`
    background: #ffffff;
    border-radius: 13px;
    position: absolute;
    width: 162px;
    top: 62px;
    right: 3px;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 11%);
`;

const MenuTabButton = styled.button`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ noborder }) => !noborder && `border-bottom: 1px solid ${colors.white};`};
    color: #000000;
`;

const UserInfoBox = styled.div`
    cursor: pointer;
    padding: 14px;
    background-color: ${colors.grey};
    color: ${colors.textColor};
    display: flex;
    align-items: center;
    border-radius: 5px;
`;

export default function MobileMenuHeader({ menuHandler }) {
    const userDetails = useUserDetails().get();
    const history = useNavigate();
    const [menuBox, setMenuBox] = useState(false);

    async function logout() {
        const PodusBase = await PodusBaseInit;
        await PodusBase.deleteCookie();
        history('/login');
    }

    return (
        <Container>
            <CloseButton onPress={() => menuHandler()}>
                <IoCloseSharp size={24} color="#000000" />
            </CloseButton>
            <ActionButtonContainer>
                <>
                    <UserInfoBox onClick={() => setMenuBox(!menuBox)}>
                        {userDetails.name}
                        <IoChevronDown color={colors.textColor} style={{ marginLeft: 5 }} />
                    </UserInfoBox>
                    {menuBox && (
                        <MenuContainer>
                            <a href={`/profile/${userDetails.profileurl}`}>
                                <MenuTabButton>Profile</MenuTabButton>
                            </a>
                            <MenuTabButton onClick={() => logout()}>Logout</MenuTabButton>
                        </MenuContainer>
                    )}
                </>
            </ActionButtonContainer>
        </Container>
    );
}
