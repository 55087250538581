import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const MenuItem = (
    { children, className, icon, active, prefix, suffix, firstchild, popperarrow, to, mobile, ...rest },
    ref,
) => {
    const menuItemRef = ref || React.createRef();
    const isDesktop = useMediaQuery({ minWidth: 767 });

    return (
        <>
            {isDesktop ? (
                <li ref={menuItemRef} className={classNames('pro-menu-item', className, { active })} {...rest}>
                    <Link to={to} className="pro-inner-item" tabIndex={0} role="button">
                        {icon ? (
                            <span className="pro-icon-wrapper">
                                <span className="pro-icon">{icon}</span>
                            </span>
                        ) : null}

                        {prefix ? <span className="prefix-wrapper">{prefix}</span> : null}
                        <span className="pro-item-content">{children}</span>
                        {suffix ? <span className="suffix-wrapper">{suffix}</span> : null}
                    </Link>
                </li>
            ) : (
                <li>
                    <a href={to}>{children}</a>
                </li>
            )}
        </>
    );
};

export default forwardRef(MenuItem);
