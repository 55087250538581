import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
`;

export default function Error({ colors, children }) {
    return <Container style={{ color: colors.red }}>{children}</Container>;
}
