import { createState, useState } from '@hookstate/core';

const globalState = createState({
    state: false,
    title: '',
    desc: '',
    type: 'error',
    closeText: false,
    onClose: () => null,
});
const wrapState = (s) => ({
    get: () => s.value,
    set: (value) => s.set(value),
});
export const useNotifierState = () => wrapState(useState(globalState));
