import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Routes
import DashboardPages, { DashboardPageLists } from './modules/DashboardPages';
import Acquisition, { AcquisitionPageLists } from './modules/Acquisition';

const Routers = () => {
    const location = useLocation();
    const [isGeneralRoutes, setGeneralRoutes] = useState('');

    useEffect(() => {
        const arraySet = location.pathname.split('/');
        if (AcquisitionPageLists.includes(location.pathname) || AcquisitionPageLists.includes(arraySet[1])) {
            setGeneralRoutes('acquisition');
        }
        if (DashboardPageLists.includes(location.pathname) || DashboardPageLists.includes(arraySet[1])) {
            setGeneralRoutes('dashboard');
        }
    }, [location]);

    if (isGeneralRoutes == 'acquisition') {
        return <Acquisition />;
    }

    if (isGeneralRoutes == 'dashboard') {
        return <DashboardPages />;
    }
    return <Acquisition />;
};

export default Routers;
