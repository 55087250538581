import React from 'react';
import { useUserDetails } from '@components/hooks';

import Users from './components/Users';
import Lawyer from './components/Lawyer';
import SuperAdmin from './components/SuperAdmin';
import Companies from './components/Companies';

export default function Sidebars() {
    const userDetails = useUserDetails().get();
    return (
        <>
            {userDetails.accounttype == 'User' && <Users />}
            {userDetails.accounttype == 'Lawyer' && <Lawyer />}
            {userDetails.accounttype == 'Super Admin' && <SuperAdmin />}
            {userDetails.accounttype == 'Admin/Lawyer' && <SuperAdmin />}
            {userDetails.accounttype == 'Company' && <Companies />}
        </>
    );
}
