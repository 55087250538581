import PodusBaseInstance from './PodusBaseInstance';

//Below Snippers is only for development...
const configs = {};
configs.systemKey = '00eebe90543da76457ac81aa8d8598';
configs.server = 'http://localhost:19006/';
configs.uri = 'https://api.podus.org/';

configs.apiUrl = 'https://api.podus.org/';

if (__DEV__) {
    configs.apiBaseUrl = 'https://api.podus.org/v1/';
    configs.domain = 'http://localhost:19006/';
} else {
    configs.domain = 'https://old.podus.org/';
    configs.apiBaseUrl = 'https://api.podus.org/v1/';
}

// configs.domain = 'http://localhost:19007/';
// configs.watchServer = 'wss://podus.org/';
configs.watchServer = 'ws://localhost:8080/';

export const config = configs;
const PodusBase = PodusBaseInstance(config);
export default PodusBase;
