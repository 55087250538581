import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaUserCircle, FaSearch, FaRegBell } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { colors } from '@gavel/components/Typo';
import { useUserDetails } from '@components/hooks';
import { Link, useNavigate } from 'react-router-dom';
import PodusBaseInit, { config } from '@modules/PodusBase';
import './index.css';

const Container = styled.div`
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 999999;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const Wrapper = styled.div`
    height: 50px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 10px;
    color: rgb(110, 107, 123);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
`;

const DateTimeContainer = styled.span`
    font-weight: 500;
    max-width: 300px;
`;

const ProfileViewContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ProfileBox = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const UserInfoContainer = styled.div`
    line-height: 17px;
`;

const Username = styled.div`
    color: rgb(110, 107, 123) !important;
    font-size: 13px;
`;

export default function Header() {
    const userDetails = useUserDetails().get();
    const history = useNavigate();

    const MonthObject = [
        'January',
        'Febuary',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const DayObject = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [DateString, SetDateString] = useState();
    const [date, setDate] = React.useState(new Date());
    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return function cleanup() {
            clearInterval(timerID);
        };
    });

    function tick() {
        setDate(new Date());
    }

    useEffect(() => {
        const date = new Date();
        const Year = date.getFullYear();
        const Month = date.getMonth();
        const Week = date.getDate();
        const Day = date.getDay();
        SetDateString(DayObject[Day] + ': ' + MonthObject[Month] + ' ' + Week + ', ' + Year);
    }, []);

    async function logout() {
        const PodusBase = await PodusBaseInit;
        await PodusBase.deleteCookie();
        history('/login');
    }

    return (
        <Container>
            <Wrapper>
                <DateTimeContainer>
                    {DateString && DateString}{' '}
                    {date.toLocaleTimeString('en', {
                        hour: 'numeric',
                        hour12: true,
                        minute: 'numeric',
                        second: '2-digit',
                    })}
                </DateTimeContainer>
                <ProfileViewContainer>
                    <ProfileContainer>
                        <Link to="/track-case">
                            <ProfileBox>
                                <IoIosSearch size={24} color={colors.primaryColor} />
                            </ProfileBox>
                        </Link>

                        <Link to="/notifications">
                            <ProfileBox>
                                <FaRegBell size={24} color={colors.primaryColor} />
                            </ProfileBox>
                        </Link>

                        <ProfileBox>
                            <FaUserCircle size={24} color={colors.primaryColor} />
                        </ProfileBox>
                        <li class="dropdown dropdown-6">
                            <UserInfoContainer>
                                <Username>{userDetails.name}</Username>
                                <Username>{userDetails.accounttype}</Username>
                            </UserInfoContainer>
                            <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                                <Link to={`/profile/${userDetails.profileurl}`}>
                                    <li class="dropdown_item-1">Profile</li>
                                </Link>
                                <li class="dropdown_item-2" onClick={logout}>
                                    Logout
                                </li>
                            </ul>
                        </li>
                    </ProfileContainer>
                </ProfileViewContainer>
            </Wrapper>
        </Container>
    );
}
