import axios from 'axios';
import Cookies from 'js-cookie';

const JCBaseStructure = {};
let wss = [];

JCBaseStructure.deleteCookie = () => {
    Cookies.remove('Podus_uft_token');
};

export const JCBaseCookie = JCBaseStructure;

const JCBaseInstance = async (config) => {
    // const userToken = Cookies.get('Podus_uft_token');
    JCBaseStructure.config = config;

    JCBaseStructure.post = async (requestUri, data = {}) => {
        const apiURL = config.apiBaseUrl + requestUri;
        data.accesstoken = Cookies.get('Podus_uft_token');
        const request = await axios({
            method: 'post',
            url: apiURL,
            data,
            headers: {
                'Content-Type': 'application/json',
                'access-control-allow-origin': '*',
            },
        });
        const getResponse = await request.data;
        const response = responseHandler(getResponse);
        return response;
    };

    JCBaseStructure.get = async (requestUri, data = {}) => {
        const apiURL = config.apiBaseUrl + requestUri;
        const request = await axios({
            method: 'get',
            url: apiURL,
            headers: { authorization: Cookies.get('Podus_uft_token') },
            Credentials: false,
        });
        const getResponse = await request.data;
        const response = responseHandler(getResponse);
        return response;
    };

    JCBaseStructure.upload = async (requestUri, data) => {
        const apiURL = config.apiBaseUrl + requestUri;
        data.append('accesstoken', Cookies.get('Podus_uft_token'));
        try {
            const response = await axios.post(apiURL, data, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                onUploadProgress(progressEvent) {
                    console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    // callback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                },
            });
            const result = await response.data;
            const passResponse = responseHandler(result);
            return passResponse;
        } catch (err) {
            console.log('error occured');
            console.log(null, err);
        }
    };

    JCBaseStructure.chatSocket = async (watchRequestUrl, callable) => {
        const webProtocol = () => {
            const wsURL = config.watchServer + watchRequestUrl;
            if (wss.length < 1) wss = [new WebSocket(wsURL)];
            wss.push(new WebSocket(wsURL));
            callable(wss[wss.length - 1]);
        };
        webProtocol();
    };

    const responseHandler = (data = {}, params = {}) => {
        const returnHandler = {};
        returnHandler.on = (type, callable) => {
            if (type === 'static') {
                return callable(data);
            } else {
                throw new Error(`Unknown fetch dataset, use either (static or watch)`);
            }
        };
        return returnHandler;
    };
    return JCBaseStructure;
};

export default JCBaseInstance;
